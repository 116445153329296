



















































































































































































































































































































.format-select {
  width: 100%;
}

.left-btn, .right-btn {
  @media(max-width: 1199px) {
    margin-top: 15px;
  }
}

.datepicker {
  @media(max-width: 1199px) {
    margin-bottom: 15px;
  }
}
